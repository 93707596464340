.container {
    display: flex;
    flex-direction: column;
    height: 100vh;   
    background-size: 160%;
    max-width: 500px; 
    margin: 0 auto;  
    background-color: #F4F4F4;
    background-image: url('../../../img/lenta-color.svg'); 
    background-size: 100% 100%; 
    background-repeat: no-repeat;
    background-position: center 250px; 
}

.section {
    flex: 1;  
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    width: 88%;
    margin: 0 auto;
    padding-top: 10px;
}

.sectionHeader {
    height: 60px;
}

.submitButtonConnect {
    display: flex; /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center; /* Вертикальное выравнивание по центру */
    padding: 10px 20px;
    background-color: #1E1E1E;
    height: 64px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 40px;
    width: 100%;

}

.textButtons {
    font-weight: 600;
    margin: 0;
}

.skipContainer {
    width: 100%;
}

.sectionButtons {
    flex: 1;  
    display: flex;
    flex-direction: column;
    justify-content: flex-end; 
    width: 88%;
    margin: 0 auto;
    padding-bottom: 30px;
}