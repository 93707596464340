.container {
    display: flex;
    flex-direction: column; /* Вертикальная ориентация */
    height: 100vh;          /* Высота контейнера на весь экран */
    max-width: 500px; 
    margin: 0 auto;  
    background: url("../../../img/lenta-wb.png") no-repeat bottom;
    background-color: #F4F4F4;
}

.completedContainer {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;    
    max-width: 500px; 
    width: 100%;
    height: 100dvh; 
    margin: 0 auto;  
    background: linear-gradient(#C5D2EA, #8384F1, #286AEC);
}

.section {
    flex: 1;                /* Равное распределение высоты между секциями */
    display: flex;
    justify-content: center; /* Центрирование содержимого по горизонтали */
    align-items: center;    /* Центрирование содержимого по вертикали */
}

.titleText {
    font-size: 32px;
    font-weight: 500;
    position: relative;
    bottom: 30px;
    right: 50px;

}

.otpInlineInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.otpInput {
    width: 60px;
    font-size: 56px;
    border: 0 none;
    appearance: none;
    text-align: center;
    transition: all 0.2s;
    background: transparent;
    border-bottom: 2px solid;
    border-color: #817f7f;
}

.button {
    display: flex;
    justify-content: center; 
    padding: 12px 24px;
    background-color: #1E1E1E;
    color: #ffffff;
    border: none;
    border-radius: 40px;
    font-weight: 500;
    font-size: 16px;
    max-width: 100%; /* Убираем любые ограничения */
    width: 90%;
    text-align: left; 
    position: relative;
    top: 45px; /* Включаем padding в расчет ширины */
}

.button:hover {
    background-color: #353435;
}

.messageText {
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
}