.container {
    display: flex;
    flex-direction: column;
    height: 100vh;   
    background-color: #f8f8f8;
    background-size: 160%;
    max-width: 500px; 
    margin: 0 auto;  
    background-color: #F4F4F4;
}

.section {
    flex: 1;  
    display: flex;
    flex-direction: column;
    justify-content: center; 
    width: 88%;
    margin: 0 auto;
}

.sectionButton {
    flex: 1;  
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
    width: 88%;
    margin: 0 auto;
    gap: 20px;
}

.sectionHeader {
    height: 60px;
}

.skipContainer {
    width: 100%;
}

.submitButtonSkip {
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: 10px 20px;
    /* background-color: #D7E6F5; */
    background-color: #ffffff;
    color: #1E1E1E;
    height: 64px;
    border: none;
    border-radius: 40px;
    cursor: not-allowed;
    font-size: 16px;
    text-align: center;
    position: relative;
    top: 5px;
    width: 100%;
}

.submitButtonConnect {
    display: flex; /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center; /* Вертикальное выравнивание по центру */
    padding: 10px 20px;
    background-color: #1e1e1e;
    height: 64px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 40px;
    position: relative;
    top: 5px;
    width: 100%;
}

.textButtons {
    font-weight: 600;
    margin: 0;
}

.wifiLight {
    border-radius: 40px;
    width: 100%;
}

.submitButtonEnabled {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 12px 24px;
    height: 64px;
    background-color: #1E1E1E;
    color: #fff;
    border: none;
    border-radius: 40px;
    cursor: not-allowed;
    font-size: 16px;
    width: 100%;
    text-align: left;
    position: relative;

}

.submitButtonConnect:hover {
    background-color: #353435;
}

.arrow {
    font-size: 20px;
    color: #ffffff;
    margin-left: auto;
}