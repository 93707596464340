.body {
    overflow: 1;
}

.container {
    display: flex;
    flex-direction: column;
    /* height: 100vh;    */
    max-width: 500px; 
    margin: 0 auto;  
    background-color: #f8f8f8;
    background-image: url('../../../img/lenta-wb.png'); 
    background-size: cover;
    background-position: center;
}

.sectionTitle {
    display: flex;
    flex-direction: column;
    width: 88%;
    margin: 0 auto;
}

.mainSection {
    display: flex;
    flex-direction: column;
    width: 88%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-grow: 1;
}

.card {
    height: 64px;
    margin-bottom: 15px;
    border-radius: 16px;
    border: none;
    padding-left: 16px;
    padding-right: 16px;
}

.paramText {
    margin: 0;
}

.section {
    flex: 1;  
    display: flex;
    flex-direction: column;
    justify-content: center; 
    width: 88%;
    margin: 0 auto;
}

.titleText {
    font-size: 32px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
}

.requiredNote {
    color: #888;
}

.form {
    flex-grow: 1; 
}

.formGroup {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.submitWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 88%;
    margin: 0 auto;
    flex-grow: 1;
    /* position: relative;
    top: -20px; */
    margin-bottom: 50px;
}

.submitButtonDisabled {
    display: flex;
    justify-content: space-between; 
    padding: 12px 24px;
    background-color: #ffffff;
    color: #ccc;
    border: none;
    border-radius: 40px;
    cursor: not-allowed;
    font-size: 16px;
    width: 100%;
    text-align: left; 
}

.submitButtonEnabled {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    padding: 12px 24px;
    background-color: #1E1E1E;
    color: #fff;
    border: none;
    border-radius: 40px;
    cursor: not-allowed;
    font-size: 16px;
    width: 100%;
    text-align: left; 
}

.arrow {
    font-size: 20px;
    color: #ccc;
    margin-left: auto; 
}

.arrowEnable {
    font-size: 20px;
    color: #ffffff;
    margin-left: auto; 
}