* {
    padding: 0;
    margin: 0;
    font-family: "Inter Tight", serif;
    font-weight: 500;
}

body {
    --react-international-phone-country-selector-border-color: #F4F4F4;
    --react-international-phone-border-color: #F4F4F4;
    height: var(--tg-viewport-height, 100vh);
    overflow: hidden;
    margin: 0;
}

#root {
    padding-top: env(safe-area-inset-top, 0);
    padding-bottom: env(safe-area-inset-bottom, 0);
    height: 100%;
    overflow-y: auto;
  }

