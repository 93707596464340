.container {
    display: flex;
    flex-direction: column;
    height: 100dvh;
    background-color: #F4F4F4;
    max-width: 500px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.finishingSection {
    display: flex;
    background-color: #ffffff;
}

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.faulted {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 88%;
    margin: 0 auto;
}

.sectionTitle {
    padding: 10px 0 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* Размещает элементы по краям */
    align-items: center;
    /* Центрирует элементы по вертикали */
    width: 88%;
    margin: 0 auto;
    text-align: center;
    height: 80px;
}

.sectionButtons {
    gap: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 88%;
    margin: 0 auto;
    z-index: 2;
    padding-bottom: 80px;
}

.settingsContainer {
    margin-left: 20px;
}

.settingsContainer:hover {
    background-color: #e0e0e0;
    border-radius: 40px;
}

.imgSettings {
    height: 25px;
    width: 25px;
    cursor: pointer;
}

.sectionHeader {
    height: 60px;
    position: fixed;
    top: 0;
    z-index: 1000;
}

.hidden {
    display: none;
}

.parameters {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 30px;
    width: 88%;
}

.mainParameters {
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: flex-start;
}

.paramContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
}

.textContainer {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.paramText {

    margin: 0;
    padding: 0;
}

.textButtons {
    font-weight: 600;
    margin: 0;
}

.textStationName {
    margin-top: 10px;
    font-size: 24px;
    color: white;
}

.lightning {
    margin-left: 8px;
    /* Добавляет отступ между текстом и изображением */
    width: 20px;
    /* Ширина изображения */
    height: 20px;
    /* Высота изображения */
}

.titleText {
    font-size: 32px;
    margin-bottom: 0 !important;
}

.submitStartCharging {
    display: flex;
    /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center;
    /* Вертикальное выравнивание по центру */
    padding: 10px 20px;
    background-color: #1E1E1E;
    height: 64px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 40px 0 0 40px;
    position: relative;
    top: 5px;
    width: 100%;
    position: relative;
    top: 40px;
}

.submitStartCharging:hover {
    background-color: #353435;
}

.holdStartCharging {
    display: flex;
    /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center;
    /* Вертикальное выравнивание по центру */
    padding: 10px 20px;
    background-color: #A1A1A1;
    height: 64px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 40px 0 0 40px;
    position: relative;
    top: 5px;
    width: 100%;
    position: relative;
    top: 40px;
}

.holdStartCharging:hover {
    background-color: #8f8f8f;
}

/* A1A1A1 */

.submitSchedule {
    display: flex;
    /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center;
    /* Вертикальное выравнивание по центру */
    padding: 10px 20px;
    background-color: #DDE4F3;
    height: 64px;
    border: none;
    color: white;
    font-size: 16px;
    border-radius: 0 40px 40px 0;
    position: relative;
    top: 5px;
    width: 100px;
    position: relative;
    top: 40px;
}

.scheduleImg {
    width: 25px;
    /* Ширина изображения */
    height: 25px;
    /* Высота изображения */
}

.circle {
    width: 230px;
    height: 230px;
    border-radius: 50%;
    background-color: #97A6E2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    animation: none;
    /* animation: gradient-rotate 3s infinite alternate; */
    /* box-shadow: 0 0 20px rgba(37, 74, 255, 0.6), 0 0 60px rgba(42, 117, 255, 0.5); */
    box-shadow: 0 0 20px rgba(37, 74, 255, 0.6);
    z-index: 10;
}

.circlePreparing {
    width: 230px;
    height: 230px;
    border-radius: 50%;
    background: conic-gradient(from 0deg,
            #e6983f,
            /* Золотой */
            #f1b47a,
            /* Оранжевый */
            #f2b135,
            /* Жёлтый */
            #f7cea4,
            /* Тёмно-оранжевый */
            #e6983f
            /* Вернуться к золотому */
        );
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0 0 20px rgba(250, 194, 9, 0.7), 0 0 60px rgba(230, 211, 129, 0.5);
    animation: glow-rotate 3s infinite linear;
}

.circleCharging {
    width: 230px;
    height: 230px;
    border-radius: 50%;
    /* background-color: #7e9ef5; */
    background: conic-gradient(from 0deg,
            #aedefa,
            /* Золотой */
            #ac80fd,
            /* Оранжевый */
            #5f3df7,
            /* Жёлтый */
            #7b84d1,
            /* Тёмно-оранжевый */
            #a4dcfd
            /* Вернуться к золотому */
        );
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0 0 20px rgba(37, 74, 255, 0.6), 0 0 60px rgba(42, 117, 255, 0.5);
    animation: glow-rotate 3s infinite linear;
}


.innercircle {
    width: 190px;
    height: 190px;
    border-radius: 50%;
    background-color: #F4F4F4;
    display: flex;
    justify-content: center;
    align-items: center;
    /* animation: counter-rotate 3s infinite linear;  */
    position: relative;
    z-index: 1;
}

.innercircleRotate {
    width: 190px;
    height: 190px;
    border-radius: 50%;
    background-color: #F4F4F4;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: counter-rotate 3s infinite linear; 
    position: relative;
    z-index: 1;
}

.text {
    color: #97A6E2;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    line-height: 1.2;
    position: absolute !important;
    animation: none;
}

.backgroundNumber {
    position: absolute;
    top: 80%;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 350px;
    font-weight: 500;
    color: rgba(151, 166, 226, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    /* Чтобы элемент не перекрывал кнопки */
    z-index: 1;
    margin: 0;
    padding: 0;
    font-family: 'Rubik', sans-serif;
}

.finishingSection {
    display: flex;
    flex-direction: column;
    width: 88%;
    margin: 0 auto;
    background-color: #F4F4F4;
}

.chargingInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.infoParameter {
    display: flex;
    flex-direction: row;
    width: 88%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 16px;
}

.modalContent {
    padding-top: 10px;
    width: 85%;
    margin: 0 auto;
}

.modalText {
    font-size: 13px;
}

.closeButton {
    height: 25px;
    border: none;
    border-radius: 16px;
    background-color: #1E1E1E;
    color: #ffffff;
    font-size: 13px;
    display: flex;
    align-items: center;
    padding: 8px;
}


@keyframes glow-rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes glow-1 {
    0% {
        background-color: #e6983f;
        /* золотой */
    }

    25% {
        background-color: #f1b47a;
        /* оранжевый */
    }

    50% {
        background-color: #f2b135;
        /* желтый */
    }

    75% {
        background-color: #f7cea4;
        /* более темный оранжевый */
    }

    100% {
        background-color: #e6983f;
        /* вернуться к золотому */
    }
}

@keyframes gradient-rotate {
    0% {
        background: linear-gradient(145deg, #ffffff, #bbdefb);
    }

    50% {
        background: linear-gradient(145deg, #e3f2fd, #2196f3);
    }

    100% {
        background: linear-gradient(145deg, #ffffff, #bbdefb);
    }
}



@keyframes counter-rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}



@media (max-height: 900px) {
    .backgroundNumber {
        font-size: 350px;
    }
}

@media (max-height: 870px) {
    .backgroundNumber {
        font-size: 300px;
        top: 80%
    }
}

@media (max-height: 830px) {
    .backgroundNumber {
        font-size: 250px;
    }
}

@media (max-height: 750px) {
    .backgroundNumber {
        font-size: 220px;
        top: 90%
    }
}

@media (max-height: 750px) {
    .backgroundNumber {
        font-size: 220px;
        top: 85%
    }
}

@media (max-height: 715px) {
    .backgroundNumber {
        color: rgba(151, 166, 226, 0);
    }
}

@media (max-height: 700px) {
    body {
        overflow: auto;
    }
}

.innerCounterContainer {
    display: flex;
    flex-direction: column;
    width: 88%;
}

.innerCounterName {
    width: 88%;
    margin: 0 auto;
    padding-left: 20px;
}

.innerData {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
}

.powerCard {
    width: 110px;
    height: 170px;
    background-color: #AEBAE3;
    border-radius: 20px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .title {
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
  
  .innerContainer {
    position: relative;
    width: 65px;
    height: 113px;
    margin-top: 16px;
    background-color: transparent;
    border: 2px solid white;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
  }
  
  .fill {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #9747FF;
    opacity: 0.5;
  }
  
  .powerText {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    font-weight: bold;
  }
  
  .unit {
    font-size: 12px;
  }