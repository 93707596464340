.container {
    display: flex;
    flex-direction: column;
    background-color: #F4F4F4;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    height: 100vh;
}

.sectionTitle {
    text-align: center;
    margin-bottom: 20px;
}

.titleText {
    font-size: 32px;

}

.mainSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.timeInputContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 88%;
    margin: 0 auto;
    gap: 10px;
}

.timeLabel {
    font-size: 16px;
    font-weight: 500;
}

.otpInlineInput {
    display: flex;
    gap: 10px;
}

.otpInput {
    width: 60px;
    font-size: 56px;
    border: 0 none;
    appearance: none;
    text-align: center;
    transition: all 0.2s;
    background: transparent;
    border-bottom: 2px solid;
    border-color: #817f7f;
}

.errorText {
    color: red;
    font-size: 14px;
    text-align: center;
}

.sectionButtons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.submitStartCharging {
    display: flex;
    /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center;
    /* Вертикальное выравнивание по центру */
    padding: 10px 20px;
    background-color: #1E1E1E;
    height: 64px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 40px;
    position: relative;
    top: 5px;
    width: 100%;
    position: relative;
    top: 40px;
}

.submitStartCharging:hover {
    background-color: #353435;
}

.textButtons {
    margin: 0;
}