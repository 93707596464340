@media (max-width: 500px) {
    .header {
        padding: 10px;
        height: 50px;
    }

    .backArrowImg, .profileImg, .chatImg {
        width: 35px;
        height: 35px;
    }

    .container {
        /* padding-top: 130px; */
        gap: 15px;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    left: 50%;
    width: 100%;
    max-width: 500px;
    height: 60px;
    background-color: #F4F4F4;
}

.container {
    width: 88%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.backArrow {
}

.backArrowImg {
    width: 43px;
    height: 39px;
}

.backArrowImg:hover {
    background-color: #e0e0e0; /* Цвет при наведении */
    border-radius: 40px;
}

.profileContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

}

.profile {
}

.profileImg {
    width: 43px;
    height: 40px;
}

.profileImg:hover {
    background-color: #e0e0e0; /* Цвет при наведении */
    border-radius: 40px;
}

.chat {
}

.chatImg {
    width: 43px;
    height: 40px;
}

.chat:hover {
    background-color: #e0e0e0; /* Цвет при наведении */
    border-radius: 40px;
}

.backIcon {
    background-color: rgb(119, 199, 176);
    width: 30;
    height: 30px;
    border-radius: 5px;
}

.title {
    flex: 1;
    text-align: center;
    font-size: 18px;
}

.chatContainer {
    display: flex;
    align-items: center;
}


