.container {
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
    background-color: #F4F4F4;
    max-width: 500px;
    margin: 0 auto;
}

.sectionTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.mainSection {
    display: flex;
    flex-direction: column;
    width: 88%;
    margin: 0 auto;
}

.sectionButtons {
    gap: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 88%;
    margin: 0 auto;
    z-index: 2;
    padding-bottom: 80px;
}

.titleText {
    font-size: 32px;
    margin: 0;
}

.card {
    height: 60px;
    background-color: #ffffff;
    border-radius: 16px;
    font-size: 18px;
    padding-left: 16px;
    padding-right: 16px;
    border: none;
    margin-bottom: 15px;
}

.sectionButtons {
    gap: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 88%;
    margin: 0 auto;
    z-index: 2;
    padding-bottom: 80px;
}

.card {
    height: 60px;
    background-color: #ffffff;
    border-radius: 16px;
    font-size: 22px;
    padding-left: 16px;
    border: none;
    margin-bottom: 15px;
}

.textButtons {
    font-weight: 600;
    margin: 0;
}

.paramText {
    margin: 5px;
}

.sectionButtons {
    gap: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 88%;
    margin: 0 auto;
    z-index: 2;
    padding-bottom: 80px;
}

.card {
    height: 60px;
    background-color: #ffffff;
    border-radius: 16px;
    font-size: 18px;
    padding-left: 16px;
    border: none;
    margin-bottom: 15px;
}

.submitStartCharging {
    display: flex;
    /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center;
    /* Вертикальное выравнивание по центру */
    padding: 10px 20px;
    background-color: #1E1E1E;
    height: 64px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 40px;
    position: relative;
    top: 5px;
    width: 100%;
    position: relative;
    top: 40px;
}

.submitStartCharging:hover {
    background-color: #383737;
}

.submitDelete {
    display: flex;
    /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center;
    /* Вертикальное выравнивание по центру */
    padding: 10px 20px;
    background-color: #BD2C2C;
    height: 64px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 40px;
    position: relative;
    top: 5px;
    width: 100%;
    position: relative;
    top: 40px;
}

.submitDelete:hover {
    background-color: #c26767;
}

.sectionButtonDelete {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 88%;
    margin: 0 auto;
    z-index: 2;
    padding-bottom: 80px;
}

.vkLogo {
    width: 50px;
    height: 45px; 
    color: #ffffff;
}
