.container {
    display: flex;
    flex-direction: column;
    /* height: 100dvh; */
    background-color: #F4F4F4;
    max-width: 500px;
    margin: 0 auto;
    overflow: hidden;
    /* position: relative; */
    /* padding-top: 110px;
    overflow: hidden; */
}

.section {
    padding: 0 0 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 88%;
    margin: 0 auto;
    align-items: center;
    text-align: center;
}

.sectionHeader {
    height: 60px;
    padding-bottom: 60px;
}

.hidden {
    display: none;
}

.mainSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 88%;
    margin: 0 auto;

}

.card {
    display: flex;
    flex-direction: column;
    text-align: center;
    background: linear-gradient(#D3D8EE, #B5BEE3);
    border-radius: 40px;
}

.cardOff {
    display: flex;
    flex-direction: column;
    text-align: center;
    background: linear-gradient(#e2e2e2, #c5c5c5);
    border-radius: 40px;
}

.information {
    margin: 40px 0 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 40px;
}

.status {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 10px 15px;
    background-color: #9cacec;
    border-radius: 40px;
    color: #ffffff;
}

.statusOff {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 10px 15px;
    background-color: #c5c5c5;
    border-radius: 40px;
    color: #ffffff;
}

.statusCharging {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 10px 15px;
    background-color: #8dc985;
    border-radius: 40px;
    color: #ffffff;
}

.statusFaulted {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 10px 15px;
    background-color: #c59191;
    border-radius: 40px;
    color: #ffffff;
}

.statusFinishing {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 10px 15px;
    background-color: #9ba0c9;
    border-radius: 40px;
    color: #ffffff;
}

.statusPrecharging {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 10px 15px;
    background-color: #c9a884;
    border-radius: 40px;
    color: #ffffff;
}

.paramText {
    color: #ffffff;
    margin: 0;
}

.parameters {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


.paramContainer {
    display: flex;
    padding: 10px 15px 10px 15px;
    background-color: #B5BEE3;
    border-radius: 40px;
}

.paramContainerOff {
    display: flex;
    padding: 10px 15px 10px 15px;
    background-color: #c5c5c5;
    border-radius: 40px;
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.sectionHeader {
    height: 60px;
}


.titleText {
    font-size: 32px;
    font-weight: 500;
}

.submitButtonConnect {
    display: flex;
    /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center;
    /* Вертикальное выравнивание по центру */
    padding: 10px 20px;
    background-color: #1E1E1E;
    height: 64px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 40px;
    position: relative;
    top: 5px;
    width: 100%;
    position: relative;
    top: 40px;
}

.submitButtonConnect:hover {
    background-color: #353435;
}

.textButtons {
    font-weight: 600;
    margin: 0;
}

.textStationName {
    margin-top: 10px;
    font-size: 24px;
    color: white;
}

.skipContainer {
    width: 100%;
}

.plusIcon {
    margin-left: 8px;
    /* Добавляет отступ между текстом и изображением */
    width: 20px;
    /* Ширина изображения */
    height: 20px;
    /* Высота изображения */
}

.sectionButtons {
    gap: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 88%;
    margin: 0 auto;
    z-index: 2;
    padding-bottom: 80px;
}


/* .slick-dots {
    display: flex !important;
    justify-content: center;
    margin-top: 20px;
}

.slick-dots li {
    margin: 0 5px;
}

.slick-dots li button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffffff; 
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease; 
}

.slick-dots li.slick-active button {
    color: #000000; 
}

.slick-slide {
    margin: 0 10px;
    color: #000000;
}

.slick-list {
    margin: 0 -10px;
} */

/* Стили для точек */

:global(.slick-dots li) {
    margin: 0 15px;
    /* Увеличиваем расстояние между точками */
}

:global(.slick-dots li button) {
    width: 15px !important;
    height: 15px !important;
    border-radius: 50%;
    background-color: #B5BEE3;
    border: none;
    outline: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Убираем серую точку внутри кнопки */
:global(.slick-dots li button::before) {
    /* color: #9cacec;
    background-color: #B5BEE3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center; */

}

/* Стиль для активной точки */
:global(.slick-dots li.slick-active button) {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #202325;
    transform: scale(1.2);
    /* Увеличение размера активной точки */
}