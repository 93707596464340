/* body {
    margin: 0;
    overflow: 0;
} */

.container {
    display: flex;
    flex-direction: column;
    background-color: #F4F4F4;
    max-width: 500px;
    margin: 0 auto;
    padding-top: 60px;
    min-height: 100vh;
    background-image: url('../../../img/lenta-d.png'); 
    background-repeat: no-repeat;
    background-position: center 40dvh; 
}

.sectionTitle {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
}

.titleText {
    height: 40px;
    font-size: 30px;
}