/* body {
    margin: 0;
    overflow: hidden;
} */

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #F4F4F4;
    max-width: 500px;
    margin: 0 auto;
    padding-top: 20px;
}

.sectionTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.mainSection {
    display: flex;
    flex-direction: column;
    width: 88%;
    margin: 0 auto;
    margin-top: 20px;
    flex-grow: 1;
}

.sectionButtons {
    gap: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 88%;
    margin: 0 auto;
    z-index: 2;
    /* padding-bottom: 80px; */
}

.titleText {
    margin-top: 40px;
    height: 40px;
    font-size: 32px;
    margin-bottom: 0 !important;
}

.card {
    height: 60px;
    background-color: #ffffff;
    border-radius: 16px;
    font-size: 22px;
    padding-left: 16px;
    border: none;
    margin-bottom: 15px;
}

.submitStartCharging {
    display: flex;
    /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center;
    /* Вертикальное выравнивание по центру */
    padding: 10px 20px;
    background-color: #1E1E1E;
    height: 64px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 40px;
    position: relative;
    top: 5px;
    width: 100%;
    position: relative;
    top: 40px;
}

.textButtons {
    font-weight: 600;
    margin: 0;
}

.paramText {
    margin: 0;
}

.sliderContainer {
    position: relative;
    flex: 1;
    padding-right: 10px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background: linear-gradient(to right, #7f81db 50%, #b7c3ec 50%); /* Цвет заполненной и пустой части */
  outline: none;
  transition: background 0.3s;
}

.slider::-webkit-slider-runnable-track {
  height: 6px;
  border-radius: 5px;
  background: transparent; /* Фон отключаем */
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #474a6d; /* Цвет кнопки-ползунка */
  cursor: pointer;
  position: relative;
  margin-top: -6px; /* Поднимаем курсор */
}

.scale {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
}

.scaleMark {
    position: relative;
    width: 1px;
    height: 10px;
    background-color: #333;
}

.scaleLabel {
    position: absolute;
    top: 12px;
    font-size: 12px;
    text-align: center;
    transform: translateX(-50%);
}

.maxPowerContainer {
    display: flex;
    display: row;
    margin-right: 5px;
    margin-left: 5px;
    /* margin: 15px; */
    padding-bottom: 20px;
}

.currentValue {
    display: flex;
    background-color: #ffffff;
    border-radius: 16px;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.textMaxPower {
    margin: 0;
}

.submitDelete {
    display: flex;
    /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center;
    /* Вертикальное выравнивание по центру */
    padding: 10px 20px;
    background-color: #BD2C2C;
    height: 64px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 40px;
    position: relative;
    top: 5px;
    width: 100%;
    position: relative;
    top: 40px;
}

.submitDelete:hover {
    background-color: #c26767;
}

.autochargeContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    border-radius: 16px;
    background-color: #ffffff;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    height: 64px;
}

.textAutoCharge {
    margin: 0;
}

.autoChargeSetting {
    height: 30px;
    width: 60px;
    border-radius: 15px;
    background-color: #BFBABA;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 2px;
}

.autoChargeSettingElement {
    width: 26px;
    height: 26px;
    background-color: #ffffff;
    border-radius: 50%;
}

.autoChargeSettingOn {
    height: 30px;
    width: 60px;
    border-radius: 15px;
    background-color: #71D97B;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 2px;
}
