/* body {
    margin: 0;
    overflow: auto;
} */

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #F4F4F4;
    max-width: 500px;
    margin: 0 auto;
}

.sectionTitle {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 88%;
    margin: 0 auto;
}

.mainSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 88%;
    margin: 0 auto;
    margin-top: 10px;
    flex-grow: 1;
    gap: 15px;
}

.sectionButtons {
    gap: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 88%;
    margin: 0 auto;
    z-index: 2;
    /* padding-bottom: 80px; */
}

.titleText {
    height: 40px;
    font-size: 24px;
}

.scheduleContainer {
    display: flex;
    flex-direction: row;
    gap: 15px;
    /* border-radius: 16px;
    background-color: #ffffff;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    height: 64px; */
}

.card {
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    height: 64px; 
    width: 100%;
    border-radius: 16px;
}

.textAutoCharge {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
}

.autoChargeSetting {
    height: 30px;
    width: 60px;
    border-radius: 15px;
    background-color: #BFBABA;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 2px;
}

.autoChargeSettingElement {
    width: 26px;
    height: 26px;
    background-color: #ffffff;
    border-radius: 50%;
}

.autoChargeSettingOn {
    height: 30px;
    width: 60px;
    border-radius: 15px;
    background-color: #71D97B;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 2px;
}

.sectionButtons {
    gap: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 88%;
    margin: 0 auto;
    z-index: 2;
    padding-bottom: 80px;
}

.submitStartCharging {
    display: flex;
    /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center;
    /* Вертикальное выравнивание по центру */
    padding: 10px 20px;
    background-color: #1E1E1E;
    height: 64px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 40px;
    position: relative;
    top: 5px;
    width: 100%;
    position: relative;
    top: 40px;
}

.submitStartCharging:hover {
    background-color: #353435;
}

.textButtons {
    margin: 0;
}