.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #F4F4F4;
    max-width: 500px;
    margin: 0 auto;
}

.mainSection {
    display: flex;
    flex-direction: column;
    width: 88%;
    margin: 0 auto;
    gap: 12px;

}

.menuButton {
    background-color: #ffffff;
    border: none;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
}

.menuButton:hover {
    background-color: #D3D8EE;
}

.buttonText {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    gap: 12px;
}

.textButtons {
    font-weight: 500;
    color: #000;
    margin: 0;
}

.arrow {
    padding-right: 16px;
    font-size: 20px;
    color: #000;
}

.sectionTitle {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 88%;
    margin: 0 auto;
    margin-bottom: 12px;
    height: 165px;
}

.titleContent {
    width: 100%;
    background-color: #ffffff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    height: 115px;
    padding-bottom: 10px;
}

.titleContent:hover {
    background-color: #D3D8EE;
}

.userImage {
    width: 100px;
    height: 100px;
    z-index: 2;
    position: absolute;
    top: 60px;
    border-radius: 50%;
}

.titleText {
    margin: 0;
}

.secret {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 88%;
    margin: 0 auto;
    border-radius: 16px;
    margin-top: 20px;
}

.secretText {
    margin: 0;
}

.secret:hover {
    background-color: #D3D8EE;
}