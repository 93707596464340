.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #F4F4F4;
    max-width: 500px;
    margin: 0 auto;
}

.mainSection {
    display: flex;
    flex-direction: column;
    width: 88%;
    margin: 0 auto;
}

.sectionTitle {
    display: flex;
    justify-content: center;
}

.titleText {
    font-size: 32px;
    font-weight: 500;
}


.manualInputContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.manualInput {
    height: 64px;
    width: 100%;
    border-radius: 16px;
    border: none;
    text-align: center;
    font-size: 22px; /* Размер текста, который вводится пользователем */
    font-weight: 600;
}

.submitButtonConnectDis {
    display: flex; /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center; /* Вертикальное выравнивание по центру */
    padding: 10px 20px;
    background-color: #949393;
    height: 50px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 16px;
    position: relative;
    width: 100%;
}

.submitButtonConnect {
    display: flex; /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center; /* Вертикальное выравнивание по центру */
    padding: 10px 20px;
    background-color: #1E1E1E;
    height: 50px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 16px;
}

.submitButtonConnect:hover {
    background-color: #353435;
}

.textButtons {
    margin: 0;
}