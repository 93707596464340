.container {
    display: flex;
    flex-direction: column;
    height: 100vh;   
    background-color: #f8f8f8;
    background-image: url('../../../img/lenta-d.png'); 
    background-repeat: no-repeat;
    background-position: right 50dvh; 
    background-size: 160%;
    max-width: 500px; 
    margin: 0 auto;  
    background-color: #F4F4F4;
}

.titleText {
    padding-top: 60px;
}

.section {
    flex: 1;  
    display: flex;
    flex-direction: column;
    justify-content: center; 
    width: 88%;
    margin: 0 auto;
}

.sectionButton {
    flex: 1;  
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
    width: 88%;
    margin: 0 auto;
    gap: 20px;
}

.sectionHeader {
    height: 60px;
}

.requiredNote {
    font-size: 12px;
    color: #888;
    margin-top: 10px;
}

.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Дает возможность прижать кнопку "Далее" к нижнему краю */
    height: 100%; /* Важный момент: формируем высоту для размещения контента */
}

.form {
    flex-grow: 1; /* Растягиваем форму, чтобы кнопка находилась внизу */
}

.formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.formGroup label {
    font-size: 14px;
    color: #000;
    margin-bottom: 5px;
}

.formGroup input,
.formGroup select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.submitWrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20px;
}

.skipContainer {
    width: 100%;
}

.submitButtonSkip {
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: 10px 20px;
    /* background-color: #D7E6F5; */
    background-color: #ffffff;
    color: #1E1E1E;
    height: 64px;
    border: none;
    border-radius: 40px;
    cursor: not-allowed;
    font-size: 16px;
    text-align: center;
    position: relative;
    top: 95px;
    width: 100%;
}

.submitButtonSkip:hover {
    background-color: #deebfa;
}

.submitButtonConnect {
    display: flex; /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center; /* Вертикальное выравнивание по центру */
    padding: 10px 20px;
    background-color: #1e1e1e;
    height: 64px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 40px;
    position: relative;
    top: 95px;
    width: 100%;
}

.submitButtonConnect:hover {
    background-color: #353435;
}

.textButtons {
    font-weight: 600;
    margin: 0;
}

.arrow {
    font-size: 20px;
    color: #ffffff;
    margin-left: auto; /* Отталкиваем стрелку от текста */
}

.mobileSignal {
    margin-left: 8px; /* Добавляет отступ между текстом и изображением */
    width: 16px; /* Ширина изображения */
    height: 16px; /* Высота изображения */
}


